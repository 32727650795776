<script>
import { Pie } from "vue-chartjs";

export default {
  extends: Pie,
  props: [
    "labels",
    "dataPoints",
    "backgroundColors",
    "apartmentsTotal",
    "avgAreaPerLabels",
    "fontFamily",
  ],

  data(vm) {
    return {
      options: {
        title: {
          fontFamily: this.fontFamily,
          display: true,
          text: `Vuokrakohteiden jakauma aikavälillä`,
          fontSize: 15,
          fontColor: "#6e707e",
        },
        legend: {
          display: true,
          labels: {
            fontFamily: this.fontFamily,
            fontColor: "#6e707e",
            fontSize: 12,
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          bodyFontFamily: this.fontFamily,
          titleFontFamily: this.fontFamily,
          backgroundColor: "#ffffff",
          bodyFontColor: "#858796",
          titleMarginBottom: 7,
          titleFontColor: "#6e707e",
          bodyFontSize: 15,
          titleFontSize: 13,
          borderColor: "#aadfeb",
          borderWidth: 1,
          xPadding: 15,
          yPadding: 15,
          displayColors: true,
          intersect: true,
          caretPadding: 10,
          mode: "index",
          callbacks: {
            title: function (tooltipItems, data) {
              const label = data.labels[tooltipItems[0].index];
              return label;
            },
            label: function (tooltipItem, data) {
              const totalApartments = data.datasets[0].data[tooltipItem.index];
              return `Kohteita: ${totalApartments} kpl | ${(
                (totalApartments / vm.apartmentsTotal) *
                100
              ).toFixed(2)} %`;
            },
            afterLabel: function (tooltipItem) {
              const avgArea = vm.avgAreaPerLabels[tooltipItem.index];
              return `ka. ${avgArea.toFixed(2)} m²`;
            },
          },
        },
      },
    };
  },

  mounted() {
    this.renderChart(
      {
        labels: this.labels,
        datasets: [
          {
            backgroundColor: this.backgroundColors,
            data: this.dataPoints,
            fill: true,
          },
        ],
      },
      this.options
    );
  },
};
</script>

<style scoped></style>
