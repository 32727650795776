<template>
  <v-dialog v-model="dialog" width="1140" persistent>
    <v-card>
      <v-container v-if="loadingRentReport">
        <!-- Loader -->
        <full-page-loader
          text="Haetaan salkkuraporttia..."
          class="full-page-loader"
        ></full-page-loader>
      </v-container>

      <!-- TABS -->
      <v-tabs
        v-if="!loadingRentReport"
        v-model="tab"
        flat
        color="primary"
        class="py-2 pb-0"
        fixed-tabs
        show-arrows
      >
        <v-tabs-slider color="primary"></v-tabs-slider>

        <v-tab v-for="(item, index) in tabs" :key="'a' + index">
          <span>{{ item }} </span>
        </v-tab>
      </v-tabs>

      <v-container v-if="!loadingRentReport && rentReportData">
        <!-- Apartment component -->
        <v-card-text v-if="tab === 0">
          <apartment-report></apartment-report>
        </v-card-text>

        <!-- Invoice component -->
        <v-card-text v-show="tab === 1" class="mt-1" style="padding-bottom: 0px">
          <invoice-report></invoice-report>
        </v-card-text>

        <!-- Receipt component -->
        <v-card-text v-show="tab === 2" class="mt-1" style="padding-bottom: 0px">
          <receipt-report v-if="receiptReportData"></receipt-report>
        </v-card-text>
      </v-container>

      <v-card-actions>
        <v-btn color="error" outlined @click="dialog = false">Poistu</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import FullPageLoader from "@/components/FullPageLoader";
import mixins from "../../mixins/mixins";
import { mapState, mapMutations, mapActions } from "vuex";
import InvoiceReport from "./InvoiceReport";
import ApartmentReport from "./ApartmentReport";
import ReceiptReport from "./ReceiptReport";

export default {
  props: {
    value: { type: Boolean, default: false },
  },

  mixins: [mixins],

  components: {
    InvoiceReport,
    ApartmentReport,
    ReceiptReport,
    FullPageLoader,
  },

  data() {
    return {
      tab: 0,
      tabs: ["Salkku", "Laskutus", "Kulut"],
      receiptsLoaded: false,
    };
  },

  computed: {
    ...mapState("report", [
      "rentReportData",
      "receiptReportData",
      "loadingRentReport",
      "reportDates",
    ]),

    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  watch: {
    value(val) {
      if (val) {
        this.tab = 0;
      } else {
        this.receiptsLoaded = false;
      }
    },

    rentReportData(data) {
      if (data) {
        this.setLoadingRentReport(false);
      }
    },

    tab(val) {
      if (val == 2) {
        if (!this.receiptsLoaded) {
          this.receiptsLoaded = true;
          this.setLoadingRentReport(true);
          this.getRentReportReceipts(this.reportDates);
        }
      }
    },
  },

  methods: {
    ...mapActions("report", ["getRentReportReceipts"]),
    ...mapMutations("report", ["setLoadingRentReport"]),
  },
};
</script>

<style scoped>
h4 {
  margin-bottom: 4px;
  margin-top: 5px;
}

p {
  margin-bottom: 3px;
}
</style>
