<template>
  <div>
    <v-row dense>
      <v-col cols="12">
        <h3 class="mb-1">
          Laskutus aikaväliltä {{ formatDate(reportDates.startDate) }} -
          {{ formatDate(reportDates.endDate) }}
        </h3>
      </v-col>
    </v-row>

    <!-- ACCOUNTDATE -->
    <v-row dense>
      <v-col cols="12" sm="6">
        <h3 class="mb-1">Kirjauspäivän mukaan</h3>

        <p>
          Kokonaissaatavat brutto sis. korot:
          {{ formatCurrency(rentReportData.invoices.accountDate.totalAmount) }}
        </p>
        <p>
          Avoimet saatavat:
          {{ formatCurrency(rentReportData.invoices.accountDate.openAmount) }}
          <span
            class="info--text"
            style="cursor: pointer"
            v-if="rentReportData.invoices.accountDate.openAmountApartments.length > 0"
            @click="
              downloadOpenAmountList(
                rentReportData.invoices.accountDate.openAmountApartments,
                'kirjauspäivän'
              )
            "
            >Avoimet vuokrakohteittain</span
          >
        </p>
        <p v-if="rentReportData.invoices.accountDate.salesInvoiceCount">
          Keskimääräinen myyntilaskun bruttosumma:
          {{
            formatCurrency(
              rentReportData.invoices.accountDate.salesInvoiceProductsTotalAmount /
                rentReportData.invoices.accountDate.salesInvoiceCount
            )
          }}
        </p>
        <p>
          Korkotuotot aikavälillä: ~{{
            formatCurrency(
              rentReportData.invoices.accountDate.totalAmount -
                rentReportData.invoices.accountDate.productsTotalAmount
            )
          }}
        </p>

        <h4 class="mb-1 mt-1">Tuotetyypeittäin</h4>
        <p>
          Vuokra brutto:
          {{ formatCurrency(rentReportData.invoices.accountDate.rentProductsTotalAmount) }}
        </p>
        <p>
          Vesi brutto:
          {{ formatCurrency(rentReportData.invoices.accountDate.waterProductsTotal) }}
        </p>
        <p>
          Kaikki muut tuotteet yhteensä brutto:
          {{ formatCurrency(rentReportData.invoices.accountDate.otherProductsTotal) }}
        </p>
        <p v-if="rentReportData.invoices.accountDate.rentProductsCountOnSaleInvoices">
          Keskimääräinen vuokra (tuotetyyppi) laskulla:
          {{
            formatCurrency(
              rentReportData.invoices.accountDate.rentProductsTotalOnSaleInvoices /
                rentReportData.invoices.accountDate.rentProductsCountOnSaleInvoices
            )
          }}
        </p>
      </v-col>

      <!-- DUEDATE -->
      <v-col cols="12" sm="6" class="pl-sm-3">
        <h3 class="mb-1">Eräpäivän mukaan</h3>

        <p>
          Kokonaissaatavat brutto sis. korot:
          {{ formatCurrency(rentReportData.invoices.dueDate.totalAmount) }}
        </p>
        <p>
          Avoimet saatavat:
          {{ formatCurrency(rentReportData.invoices.dueDate.openAmount) }}
          <span
            class="info--text"
            style="cursor: pointer"
            v-if="rentReportData.invoices.dueDate.openAmountApartments.length > 0"
            @click="
              downloadOpenAmountList(
                rentReportData.invoices.dueDate.openAmountApartments,
                'eräpäivän'
              )
            "
            >Avoimet vuokrakohteittain</span
          >
        </p>
        <p v-if="rentReportData.invoices.dueDate.salesInvoiceCount">
          Keskimääräinen myyntilaskun bruttosumma:
          {{
            formatCurrency(
              rentReportData.invoices.dueDate.salesInvoiceProductsTotalAmount /
                rentReportData.invoices.dueDate.salesInvoiceCount
            )
          }}
        </p>

        <p>
          Korkotuotot aikavälillä: ~{{
            formatCurrency(
              rentReportData.invoices.dueDate.totalAmount -
                rentReportData.invoices.dueDate.productsTotalAmount
            )
          }}
        </p>

        <h4 class="mb-1 mt-1">Tuotetyypeittäin</h4>
        <p>
          Vuokra brutto:
          {{ formatCurrency(rentReportData.invoices.dueDate.rentProductsTotalAmount) }}
        </p>
        <p>
          Vesi brutto:
          {{ formatCurrency(rentReportData.invoices.dueDate.waterProductsTotal) }}
        </p>
        <p>
          Kaikki muut tuotteet yhteensä brutto:
          {{ formatCurrency(rentReportData.invoices.dueDate.otherProductsTotal) }}
        </p>
        <p v-if="rentReportData.invoices.dueDate.rentProductsCountOnSaleInvoices">
          Keskimääräinen vuokra (tuotetyyppi) laskulla:
          {{
            formatCurrency(
              rentReportData.invoices.dueDate.rentProductsTotalOnSaleInvoices /
                rentReportData.invoices.dueDate.rentProductsCountOnSaleInvoices
            )
          }}
        </p>

        <!-- {{ rentReportData.invoices.dueDate.openAmountApartments }} -->
      </v-col>

      <v-col class="pl-sm-2" cols="12" sm="6"> </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import mixins from "../../mixins/mixins";
import moment from "moment";

export default {
  props: {
    dates: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },

  mixins: [mixins],

  computed: {
    ...mapState("report", ["rentReportData", "reportDates"]),
    ...mapState("account", ["currentUser"]),
  },

  methods: {
    async downloadOpenAmountList(apartments, dateType) {
      try {
        const start = moment(this.reportDates.startDate).format("DD.MM.YYYY");
        const end = moment(this.reportDates.endDate).format("DD.MM.YYYY");
        const additionalInfo = [
          `Avoimet saatavat ${dateType} mukaan`,
          `Asiakas: ${this.currentUser.currentAccount.name}`,
          `Raportin aikaväli: ${start} - ${end}`,
          `Raportin päivämäärä: ${moment().format("DD.MM.YYYY")}`,
        ];

        const buffer = await this.createExcelBuffer(apartments, additionalInfo, true, false, false);

        this.downloadExcel(
          buffer,
          `Avoimet saatavat ${dateType} mukaan_${start}-${end}_${this.currentUser.currentAccount.name}`
        );
      } catch (err) {
        this.showPopup(err, "error");
      }
    },
  },
};
</script>

<style></style>
