<template>
  <div class="py-3 min-w-0 w-full">
    <div class="display-1">Raportit</div>

    <v-card class="mt-2">
      <v-card-title>Salkkuraportit</v-card-title>

      <v-card-text>
        <v-container>
          <v-form ref="reportForm">
            <v-row dense>
              <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                <v-select
                  v-model="selectedReport"
                  :items="permissionReports"
                  item-value="val"
                  item-text="text"
                  :hint="reportHint"
                  persistent-hint
                  outlined
                  dense
                  :rules="validations.required"
                ></v-select>
              </v-col>

              <!-- Netincome report accoutbase selection -->
              <v-col
                v-if="selectedReport === 'netIncomeReport'"
                cols="12"
                sm="6"
                md="4"
                lg="3"
                xl="2"
              >
                <v-select
                  v-model="accountingBase"
                  :items="accountingBaseValues"
                  item-value="val"
                  item-text="text"
                  :hint="
                    accountingBase == 'accountDate'
                      ? 'Laskujen kirjauspäivän mukaan'
                      : 'Suorituksien kirjauspäivän mukaan'
                  "
                  persistent-hint
                  label="Laskentaperuste"
                  outlined
                  dense
                  :rules="validations.required"
                ></v-select>
              </v-col>

              <!-- Assignment report date selection option -->
              <v-col
                v-if="selectedReport === 'assignmentReport'"
                cols="12"
                sm="6"
                md="4"
                lg="3"
                xl="2"
              >
                <v-select
                  v-model="assignmentDateOption"
                  :items="assignmentDateOptions"
                  item-value="val"
                  item-text="text"
                  label="Sisällytä sopimukset"
                  outlined
                  dense
                  :rules="validations.required"
                ></v-select>
              </v-col>

              <!-- Assignment report invoicing selection option -->
              <v-col
                v-if="selectedReport === 'assignmentReport'"
                cols="12"
                sm="6"
                md="4"
                lg="3"
                xl="2"
              >
                <v-select
                  v-model="assignmentInvoicingOption"
                  :items="assignmentInvoicingOptions"
                  item-value="val"
                  item-text="text"
                  label="Laskutuskausi"
                  outlined
                  dense
                  :rules="validations.required"
                ></v-select>
              </v-col>

              <v-col cols="12" sm="6" md="3" lg="3" xl="2">
                <v-menu
                  ref="startMenu"
                  v-model="startMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  max-width="290px"
                  min-width="290px"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      :value="startDateFormatted"
                      outlined
                      dense
                      label="Alkaen"
                      v-bind="attrs"
                      v-on="on"
                      append-icon="mdi-calendar"
                      ref="startDateFormatted"
                      v-prevent-manual-input
                      hide-details
                      :rules="validations.required"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dates.startDate"
                    :allowed-dates="allowedDates"
                    :type="calendarType"
                    first-day-of-week="1"
                    @input="startMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12" sm="6" md="3" lg="3" xl="2">
                <v-menu
                  ref="endMenu"
                  v-model="endMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  max-width="290px"
                  min-width="290px"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      :value="endDateFormatted"
                      outlined
                      dense
                      label="Päättyen"
                      v-bind="attrs"
                      v-on="on"
                      append-icon="mdi-calendar"
                      v-prevent-manual-input
                      hide-details
                      :rules="validations.required"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dates.endDate"
                    :allowed-dates="allowedDates"
                    :type="calendarType"
                    first-day-of-week="1"
                    @input="endMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                <v-btn color="info" class="mt-1" @click="getSelectedReport"
                  >Hae valittu raportti</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
    </v-card>

    <v-card class="mt-2">
      <v-card-title>Rent Roll-raportti</v-card-title>
      <v-card-subtitle
        >Raportti tarjoaa ajantasaisen yhteenvedon kiinteistöjen vuokrasopimuksista, vuokralaisista
        ja vuokratuotoista.</v-card-subtitle
      >
      <v-card-text>
        <v-container>
          <v-row dense>
            <v-col md="4">
              <v-btn color="info" @click="downloadRentRollReport">Hae Rent Roll-raportti</v-btn>
              <br />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>

    <v-card class="mt-2">
      <v-card-title>Vakuusraportti</v-card-title>
      <v-card-subtitle>Tiedot voimassaolevista vakuuksista</v-card-subtitle>
      <v-card-text>
        <v-container>
          <v-row dense>
            <v-col md="4">
              <v-btn color="info" @click="getGuaranteeReport">Hae vakuusraportti</v-btn>
              <br />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>

    <v-card class="mt-2">
      <v-card-title>Vuokralaisraportti</v-card-title>
      <v-card-subtitle
        >Vuokralaisten tiedot voimassa olevista vuokrasopimuksista ja
        maksutiedoista</v-card-subtitle
      >
      <v-card-text>
        <v-container>
          <v-row dense>
            <v-col md="4">
              <v-btn color="info" @click="fetchTenantReport">Hae vuokralaisraportti</v-btn>
              <br />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>

    <!-- Report data -->
    <rent-report-data
      v-model="rentReportDataDialog"
      :dates="{ startDate: startDateFormatted, endDate: endDateFormatted }"
    ></rent-report-data>

    <!-- Download loader -->
    <download-loader
      v-model="showDownloadLoader"
      :loading="showDownloadLoader"
      text="Odota, ladataan raportia..."
    ></download-loader>
  </div>
</template>

<script>
import { apiAgent } from "../../services/apiAgent";
import axiosMethods from "@/mixins/axios";
import mixins from "../../mixins/mixins";
import moment from "moment";
import { mapActions, mapMutations, mapState } from "vuex";
import validations from "@/validations";
import RentReportData from "./RentReportDialog.vue";
import {
  createNetIncomeReportData,
  createContractReportData,
  createAssignmentReportData,
  getRentRollData,
} from "@/utils/reportHelpers";
import DownloadLoader from "../../components/DownloadLoader.vue";

export default {
  title: "Raportit",

  mixins: [mixins],

  components: {
    RentReportData,
    DownloadLoader,
  },

  data() {
    return {
      startMenu: false,
      endMenu: false,
      calendarType: "month",
      selectedReport: "fullTaxReport",
      reportItems: [
        { text: "Veroraportti", val: "fullTaxReport", permission: null },
        { text: "Laskuluettelo", val: "salesInvoiceReport", permission: null },
        { text: "Salkkuraportti", val: "rentReport", permission: null },
        { text: "Vuokralaisten muistiinpanot", val: "tenantNoteReport", permission: null },
        { text: "Nettotulot", val: "netIncomeReport", permission: null },
        { text: "Vuokrasopimukset", val: "contractReport", permission: null },
        {
          text: "Toimeksiantoraportti",
          val: "assignmentReport",
          permission: { resource: "realtor", permission: "assignment" },
        },
      ],
      startMenuPayment: false,
      endMenuPayment: false,
      dates: {
        startDate: "",
        endDate: "",
      },
      accountingBase: "accountDate",
      accountingBaseValues: [
        { text: "Laskuperusteinen", val: "accountDate" },
        { text: "Maksuperusteinen", val: "paymentDate" },
      ],
      assignmentDateOption: "intersection",
      assignmentDateOptions: [
        { text: "Kaikki aikavälillä voimassa olleet", val: "intersection" },
        { text: "Ei aikavälillä päättyneitä", val: "endDateCropped" },
      ],
      assignmentInvoicingOption: "january",
      assignmentInvoicingOptions: [
        { text: "Kaikki", val: "all" },
        { text: "Tammikuu", val: "january" },
        { text: "Heinäkuu", val: "july" },
      ],
      showDownloadLoader: false,
      name: "raportti",
      startDateFormatted: "",
      endDateFormatted: "",
      downloading: false,
      downloadDialog: false,
      reportHints: [],
      rentReportDataDialog: false,
      validations,
    };
  },

  watch: {
    "dates.startDate"(val) {
      this.startDateFormatted = this.formatDate(val);
    },

    "dates.endDate"(val) {
      let newDate = "";
      if (this.selectedReport === "fullTaxReport" || this.selectedReport === "tenantNoteReport") {
        newDate = this.formatMonthDate(val);
      } else if (
        this.selectedReport === "salesInvoiceReport" ||
        this.selectedReport === "rentReport" ||
        this.selectedReport === "netIncomeReport" ||
        this.selectedReport === "contractReport" ||
        this.selectedReport === "assignmentReport"
      ) {
        newDate = this.formatDate(val);
      }

      this.endDateFormatted = newDate;
    },

    selectedReport(newVal, oldVal) {
      if (newVal === "fullTaxReport" || newVal == "tenantNoteReport") {
        this.dates.startDate = this.dates.startDate
          ? moment(this.dates.startDate).format("YYYY-MM")
          : moment().format("YYYY-MM");

        this.dates.endDate = this.dates.endDate
          ? moment(this.dates.endDate).format("YYYY-MM")
          : moment().format("YYYY-MM");

        // set calendar type
        this.calendarType = "month";
      } else if (
        newVal === "salesInvoiceReport" ||
        newVal === "rentReport" ||
        newVal === "netIncomeReport" ||
        newVal === "contractReport" ||
        newVal === "assignmentReport"
      ) {
        if (
          oldVal === "salesInvoiceReport" ||
          oldVal === "rentReport" ||
          oldVal === "netIncomeReport" ||
          newVal === "contractReport" ||
          newVal === "assignmentReport"
        ) {
          this.dates.startDate = this.dates.startDate
            ? moment(this.dates.startDate).format("YYYY-MM-DD")
            : moment().startOf("month").format("YYYY-MM-DD");

          this.dates.endDate = this.dates.endDate
            ? moment(this.dates.endDate).format("YYYY-MM-DD")
            : moment().endOf("month").format("YYYY-MM-DD");
        } else {
          this.dates.startDate = this.dates.startDate
            ? moment(this.dates.startDate).format("YYYY-MM-DD")
            : moment().startOf("month").format("YYYY-MM-DD");

          this.dates.endDate = this.dates.endDate
            ? moment(this.dates.endDate).endOf("month").format("YYYY-MM-DD")
            : moment().endOf("month").format("YYYY-MM-DD");
        }

        // Set dates to state
        // set calendar type
        this.calendarType = "date";
      }
    },

    async tenantReportData(data) {
      try {
        const additionalInfo = [
          `Vuokralaiset`,
          `Asiakas: ${this.currentUser.currentAccount.name}`,
          `Raportin päivämäärä: ${moment().format("DD.MM.YYYY")}`,
        ];
        const buffer = await this.createExcelBuffer(data, additionalInfo, true, false);

        this.downloadExcel(buffer, "vuokralaisraportti");

        this.showDownloadLoader = false;
      } catch (err) {
        this.showDownloadLoader = false;
        this.showPopup(err, "error");
      }
    },
  },

  computed: {
    ...mapState("account", ["currentUser"]),
    ...mapState("report", ["tenantReportData"]),

    permissionReports() {
      return this.reportItems.filter((el) => {
        return (
          el.permission === null ||
          this.isAuthorized(el.permission.resource, el.permission.permission)
        );
      });
    },

    reportHint() {
      const report = this.selectedReport;
      if (!report) return "";

      if (report === "fullTaxReport") {
        return "Vuokrakohdekohtainen veroraportti valitulta aikaväliltä.";
      } else if (report === "salesInvoiceReport") {
        return "Laskut halutulta aikaväliltä";
      } else if (report === "contractReport") {
        return "Voimassa olevat vuokrasopimukset, vuokran määrä ja vakuudet";
      }
      return "";
    },
  },

  methods: {
    ...mapActions("report", [
      "getTenantReport",
      "getRentReport",
      "getContractReport",
      "getAssignmentReport",
      "getRentRollReport",
    ]),
    ...mapMutations("report", ["setLoadingRentReport", "setReportDates"]),

    getSelectedReport() {
      if (this.$refs.reportForm.validate()) {
        const report = this.selectedReport;

        // Error checking
        if (report == "fullTaxReport" || report == "tenantNoteReport") {
          if (moment(this.dates.startDate).isAfter(this.dates.endDate)) {
            return this.showPopup("Aloituspäivä ei voi olla päättymispäivän jälkeen.", "error");
          }
        } else {
          if (moment(this.dates.startDate).isSameOrAfter(this.dates.endDate)) {
            return this.showPopup(
              "Aloituspäivän pitää olla vähintään yhtä päivää ennen päättymispäivää.",
              "error"
            );
          }
        }

        if (report == "fullTaxReport") return this.getFullTaxReport();
        if (report == "salesInvoiceReport") return this.getSalesInvoiceReport();
        if (report == "netIncomeReport") return this.getNetIncomeReport();
        if (report == "rentReport") return this.getRentReportData();
        if (report == "tenantNoteReport") return this.getTenantNoteReport();
        if (report == "contractReport") return this.getContractReportData();
        if (report == "assignmentReport") return this.getAssignmentReportData();
      }
    },

    async getRentReportData() {
      try {
        // set dates to state
        this.setReportDates({ field: "startDate", val: this.dates.startDate });
        this.setReportDates({ field: "endDate", val: this.dates.endDate });
        // set loading
        this.setLoadingRentReport(true);
        this.rentReportDataDialog = true;
        await this.getRentReport(this.dates);
      } catch (err) {
        this.showPopup(err, "error");
        this.rentReportDataDialog = false;
      }
    },

    async getAssignmentReportData() {
      try {
        this.showDownloadLoader = true;

        const itemMap = await this.getAssignmentReport({
          dates: this.dates,
          assignmentDateOption: this.assignmentDateOption,
          assignmentInvoicingOption: this.assignmentInvoicingOption,
        });

        if (!itemMap) {
          this.showDownloadLoader = false;
          this.showPopup("Dataa ei löytynyt", "error");
        } else {
          const items = createAssignmentReportData(itemMap);

          const { reportDateFormatted, reportRangeFormatted, formattedDates } =
            this.getReportDatesAndTime();

          const invoicingPeriodFormatted = this.assignmentInvoicingOptions.find(
            (el) => el.val === this.assignmentInvoicingOption
          );

          const additionalInfo = [
            `Toimeksiantoraportti`,
            `Asiakas: ${this.currentUser.currentAccount.name}`,
            `Laskutuskausi: ${invoicingPeriodFormatted.text}`,
            `Raportin aikaväli: ${reportRangeFormatted}`,
            `Raportin päivämäärä: ${reportDateFormatted}`,
          ];

          const sumColumns = [
            "Laskutetut vuokrat",
            "Palkkio sis. alv",
            "Palkkio alv",
            "Palkkio ilman alv",
          ];

          const buffer = await this.createExcelBuffer(
            items,
            additionalInfo,
            true,
            true,
            true,
            sumColumns
          );

          this.downloadExcel(
            buffer,
            "Toimeksiantoraportti",
            formattedDates.start,
            formattedDates.end
          );
          this.showDownloadLoader = false;
        }
      } catch (err) {
        this.showDownloadLoader = false;
        this.showPopup(err, "error");
      }
    },

    getReportDatesAndTime(endOfMonth = false) {
      const start = moment(this.dates.startDate).format("DD.MM.YYYY");

      const end = endOfMonth
        ? moment(this.dates.endDate).endOf("month").format("DD.MM.YYYY")
        : moment(this.dates.endDate).format("DD.MM.YYYY");

      return {
        reportDateFormatted: `${moment().format("DD.MM.YYYY")}`,
        reportRangeFormatted: `${start} - ${end}`,
        formattedDates: { start, end },
      };
    },

    async getFullTaxReport() {
      try {
        this.showDownloadLoader = true;

        const res = await apiAgent({
          method: "POST",
          url: "/api/v1/reports/getFullTaxReport",
          data: this.dates,
        });

        if (res.data.data.length == 0) {
          this.showDownloadLoader = false;
          return this.showPopup("Ei tuloksia, kokeile muita päivämääriä", "error");
        }

        const { reportDateFormatted, reportRangeFormatted, formattedDates } =
          this.getReportDatesAndTime(true);

        const additionalInfo = [
          `Veroraportti`,
          `Asiakas: ${this.currentUser.currentAccount.name}`,
          `Raportin aikaväli: ${reportRangeFormatted}`,
          `Raportin päivämäärä: ${reportDateFormatted}`,
        ];

        const sumColumns = [
          "Saatavat kirjauspäivän mukaan",
          "Maksusuoritukset",
          "Vesimaksut",
          "Hoitovastikkeet",
          "Kaikki rahoitusvastikkeet",
          "Kiinteät vähennyskelpoiset kulut",
          "Muuttuvat vähennyskelpoiset kulut",
          "Verotettava tulo",
        ];

        const buffer = await this.createExcelBuffer(
          res.data.data,
          additionalInfo,
          true,
          true,
          true,
          sumColumns
        );

        this.downloadExcel(buffer, "Veroraportti", formattedDates.start, formattedDates.end);

        this.showDownloadLoader = false;
      } catch (err) {
        this.showDownloadLoader = false;
        const error = await axiosMethods.validateRequest(err);
        this.showPopup(error, "error");
      }
    },

    async getSalesInvoiceReport() {
      try {
        this.showDownloadLoader = true;

        const res = await apiAgent({
          method: "POST",
          url: "/api/v1/reports/sales-invoice-report",
          data: this.dates,
        });

        if (res.data.invoices.length == 0) {
          this.showDownloadLoader = false;
          return this.showPopup("Ei tuloksia, kokeile muita päivämääriä", "error");
        }

        const { reportDateFormatted, reportRangeFormatted, formattedDates } =
          this.getReportDatesAndTime();

        const additionalInfo = [
          `Laskuluettelo`,
          `Asiakas: ${this.currentUser.currentAccount.name}`,
          `Raportin aikaväli: ${reportRangeFormatted}`,
          `Raportin päivämäärä: ${reportDateFormatted}`,
        ];
        const sumColumns = ["Netto", "Brutto", "Vero"];

        const buffer = await this.createExcelBuffer(
          res.data.invoices,
          additionalInfo,
          true,
          true,
          true,
          sumColumns
        );

        this.downloadExcel(
          buffer,
          `Laskuluettelo_${formattedDates.start}-${formattedDates.end}_${this.currentUser.currentAccount.name}`
        );

        this.showDownloadLoader = false;
      } catch (err) {
        this.showDownloadLoader = false;
        const error = await axiosMethods.validateRequest(err);
        this.showPopup(error, "error");
      }
    },

    async getNetIncomeReport() {
      try {
        this.showDownloadLoader = true;

        const res = await apiAgent({
          method: "POST",
          url: "/api/v1/reports/net-income-report",
          data: { dates: this.dates, accountingBase: this.accountingBase },
        });

        if (Object.keys(res.data.timeRangeApartmentMap).length == 0) {
          this.showDownloadLoader = false;
          return this.showPopup("Ei tuloksia, kokeile muita päivämääriä", "error");
        }

        const { reportDateFormatted, reportRangeFormatted, formattedDates } =
          this.getReportDatesAndTime();

        const data = createNetIncomeReportData(
          this.accountingBase,
          res.data.timeRangeApartmentMap,
          res.data.cumulativeApartmentMap,
          this.dates.endDate
        );

        const additionalInfo = [
          `Nettotulolaskelma - ${
            this.accountingBase === "accountDate" ? "Laskuperusteinen" : "Maksuperusteinen"
          }`,
          `Asiakas: ${this.currentUser.currentAccount.name}`,
          `Raportin aikaväli: ${reportRangeFormatted}`,
          `Raportin päivämäärä: ${reportDateFormatted}`,
        ];

        const startOfYear = moment(this.dates.endDate).startOf("year").format("DD.MM.YYYY");
        const endOfYear = moment(this.dates.endDate).endOf("year").format("DD.MM.YYYY");

        let sumColumns;

        if (this.accountingBase === "accountDate") {
          sumColumns = [
            "Pinta-ala",
            "Vuokrasaatavat",
            "Vesimaksusaatavat",
            "Autopaikkasaatavat",
            "Mediamaksusaatavat",
            "Muut saatavat",
            "Hoitokulut\n(hoitovastike + vesi)",
            "Rahoitusvastikkeet",
            "Muut kulut",
            "Netto",
            "Netto €/m²",
            `Netto €\n${startOfYear}-${endOfYear}`,
          ];
        } else {
          sumColumns = [
            "Pinta-ala",
            "Maksusuoritukset",
            "Hoitokulut\n(hoitovastike + vesi)",
            "Rahoitusvastikkeet",
            "Muut kulut",
            "Netto",
            "Netto €/m²",
            `Netto €\n${startOfYear}-${endOfYear}`,
          ];
        }

        const buffer = await this.createExcelBuffer(
          data,
          additionalInfo,
          true,
          true,
          true,
          sumColumns
        );

        const fileName = `Nettotuloraportti_${formattedDates.start}-${formattedDates.end}_${this.currentUser.currentAccount.name}`;
        this.downloadExcel(buffer, fileName);

        this.showDownloadLoader = false;
      } catch (err) {
        this.showDownloadLoader = false;
        const error = await axiosMethods.validateRequest(err);
        this.showPopup(error, "error");
      }
    },

    async getGuaranteeReport() {
      try {
        this.showDownloadLoader = true;

        const res = await apiAgent({
          method: "POST",
          url: "/api/v1/reports/getGuaranteeReport",
        });

        if (res.data.data.length == 0) {
          this.showDownloadLoader = false;
          return this.showPopup("Ei voimassaolevia vakuuksia", "error");
        }

        const { reportDateFormatted } = this.getReportDatesAndTime();

        const additionalInfo = [
          `Vakuusraportti`,
          `Asiakas: ${this.currentUser.currentAccount.name}`,
          `Raportin päivämäärä: ${reportDateFormatted}`,
        ];
        const sumColumns = ["Vastaanotetut", "Palautetut"];

        const buffer = await this.createExcelBuffer(
          res.data.data,
          additionalInfo,
          true,
          true,
          true,
          sumColumns
        );

        this.downloadExcel(buffer, "Vakuusraportti");

        this.showDownloadLoader = false;
      } catch (err) {
        this.showDownloadLoader = false;
        const error = await axiosMethods.validateRequest(err);
        this.showPopup(error, "error");
      }
    },

    async downloadRentRollReport() {
      try {
        this.showDownloadLoader = true;

        const apartmentMap = await this.getRentRollReport();
        const data = getRentRollData(apartmentMap);

        if (data.length === 0) {
          this.showDownloadLoader = false;
          return this.showPopup("Ei kohteita", "error");
        }

        const additionalInfo = [
          `Rent Roll-raportti`,
          `Asiakas: ${this.currentUser.currentAccount.name}`,
          `Raportin päivämäärä: ${moment().format("DD.MM.YYYY")}`,
        ];

        const sumColumns = ["Vuokra / kk", "Erilliskorvaukset"];

        const buffer = await this.createExcelBuffer(
          data,
          additionalInfo,
          true,
          true,
          true,
          sumColumns
        );

        this.downloadExcel(
          buffer,
          `${moment().format("DD.MM.YYYY")}_${this.currentUser.currentAccount.name}_Rent Roll`
        );

        this.showDownloadLoader = false;
      } catch (err) {
        this.showDownloadLoader = false;
        const error = await axiosMethods.validateRequest(err);
        this.showPopup(error, "error");
      }
    },

    async fetchTenantReport() {
      try {
        this.showDownloadLoader = true;
        await this.getTenantReport();
      } catch (err) {
        this.showDownloadLoader = false;
        const error = await axiosMethods.validateRequest(err);
        this.showPopup(error, "error");
      }
    },

    async getTenantNoteReport() {
      try {
        this.showDownloadLoader = true;

        const res = await apiAgent({
          method: "POST",
          url: "/api/v1/reports/tenant-note-report",
          data: this.dates,
        });

        if (res.data.report.length == 0) {
          this.showDownloadLoader = false;
          return this.showPopup("Ei muistiinpanoja aikavälillä", "error");
        }

        const { reportDateFormatted, reportRangeFormatted, formattedDates } =
          this.getReportDatesAndTime(true);

        const additionalInfo = [
          `Vuokralaisten muistiinpanot`,
          `Asiakas: ${this.currentUser.currentAccount.name}`,
          `Raportin aikaväli: ${reportRangeFormatted}`,
          `Raportin päivämäärä: ${reportDateFormatted}`,
        ];
        const buffer = await this.createExcelBuffer(res.data.report, additionalInfo, true, false);

        this.downloadExcel(
          buffer,
          "Vuokralaisten muistiinpanot",
          formattedDates.start,
          formattedDates.end
        );

        this.showDownloadLoader = false;
      } catch (err) {
        this.showDownloadLoader = false;
        const error = await axiosMethods.validateRequest(err);
        this.showPopup(error, "error");
      }
    },

    async getContractReportData() {
      try {
        this.showDownloadLoader = true;

        const contractMap = await this.getContractReport(this.dates);
        const data = createContractReportData(contractMap);

        const { reportDateFormatted, reportRangeFormatted, formattedDates } =
          this.getReportDatesAndTime();

        const additionalInfo = [
          `Vuokrasopimukset`,
          `Asiakas: ${this.currentUser.currentAccount.name}`,
          `Raportin aikaväli: ${reportRangeFormatted}`,
          `Raportin päivämäärä: ${reportDateFormatted}`,
        ];

        const buffer = await this.createExcelBuffer(data, additionalInfo, true, false, true);

        this.downloadExcel(buffer, "Vuokrasopimukset", formattedDates.start, formattedDates.end);
        this.showDownloadLoader = false;
      } catch (err) {
        this.showDownloadLoader = false;
        this.showPopup(err, "error");
      }
    },

    allowedDates(val) {
      if (val) {
        const today = new Date(moment(new Date()).format("YYYY-MM-DD"));
        const thisYear = today.getFullYear();
        const thisMonth = today.getMonth();
        const valYear = new Date(val).getFullYear();
        const valMonth = new Date(val).getMonth();

        if (valYear < thisYear) return true;
        else if (thisYear == valYear && valMonth <= thisMonth) return true;
        else return false;
      }
      return false;
    },
  },
};
</script>

<style scoped>
.question {
  width: 10px;
  height: 10px;
  padding: 2px 10px;
  color: #333;
  background-color: rgb(235, 235, 0);
  border-radius: 50%;
}
</style>
