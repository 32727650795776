<template>
  <div>
    <p class="product-wrapper">
      <span>Alviton:</span>
      <strong>{{ formatCurrency(subAmount) }}</strong>
    </p>

    <p class="product-wrapper">
      <span>Alv 25,5 %:</span>
      <strong>
        {{ formatCurrency(receipts.vat255) }}
      </strong>
    </p>
    <p class="product-wrapper">
      <span>Alv 24 %:</span>
      <strong>
        {{ formatCurrency(receipts.vat24) }}
      </strong>
    </p>
    <p class="product-wrapper">
      <span>Alv 14 %:</span>
      <strong>
        {{ formatCurrency(receipts.vat14) }}
      </strong>
    </p>
    <p class="product-wrapper">
      <span>Alv 10 %:</span>
      <strong>
        {{ formatCurrency(receipts.vat10) }}
      </strong>
    </p>

    <p class="product-wrapper success--text">
      <strong>Yhteensä:</strong>
      <strong>
        {{ formatCurrency(receipts.totalAmount) }}
      </strong>
    </p>
  </div>
</template>

<script>
import mixins from "../../mixins/mixins";

export default {
  mixins: [mixins],

  props: {
    receipts: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },

  computed: {
    subAmount() {
      return (
        this.receipts.totalAmount -
        this.receipts.vat255 -
        this.receipts.vat24 -
        this.receipts.vat14 -
        this.receipts.vat10
      );
    },
  },

  methods: {},
};
</script>

<style scoped>
.product-wrapper {
  max-width: 180px;
  display: flex;
  align-content: center;
  justify-content: space-between;
}
</style>
